var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "article-grid" },
    [
      _c("h4", { staticClass: "px-2 text-uppercase mb-1 mt-3" }, [
        _vm._v(" " + _vm._s(_vm.$t("recaledProducts.title")) + " ")
      ]),
      _c("category-block", {
        staticClass: "category-block category-block-1",
        attrs: { target: _vm.category, position: "position1" }
      }),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            _vm._l(_vm.articleList, function(article) {
              return _c(
                "v-col",
                {
                  key: article.id,
                  attrs: { cols: "12", sm: "6", md: "6", lg: "4" }
                },
                [_c("RecalledProduct", { attrs: { product: article } })],
                1
              )
            }),
            1
          ),
          _vm.articleList.length < 1 && !_vm.initLoading
            ? _c(
                "div",
                {
                  staticClass:
                    "text-center font-weight-semibold secondary--text text-body-0 py-6"
                },
                [_vm._v(" " + _vm._s(_vm.noDataMessage) + " ")]
              )
            : _vm._e(),
          _vm.hasMoreItems()
            ? _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "intersect",
                      rawName: "v-intersect",
                      value: _vm.onIntersect,
                      expression: "onIntersect"
                    }
                  ],
                  staticClass: "mt-3",
                  attrs: {
                    block: "",
                    text: "",
                    large: "",
                    depressed: "",
                    rounded: "",
                    color: "primary",
                    loading: _vm.loading
                  },
                  on: {
                    click: function($event) {
                      return _vm.loadMore()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("recipes.showMore")))]
              )
            : _vm._e()
        ],
        1
      ),
      _c("category-block", {
        staticClass: "category-block category-block-2",
        attrs: { target: _vm.category, position: "position2" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }