var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "recalled-product-card align-center",
      class: _vm.size,
      attrs: {
        outlined: "",
        to: {
          name: _vm.product.articleType.name,
          params: { pageName: _vm.product.slug }
        }
      }
    },
    [
      _c("div", { staticClass: "d-flex align-center" }, [
        _c(
          "div",
          { staticClass: "action-icons" },
          [_c("v-icon", [_vm._v("$richiamoProdotti")])],
          1
        ),
        _c(
          "div",
          { staticClass: "product-img-wrapper" },
          [
            _c("v-img", {
              staticClass: "product-img",
              attrs: {
                src: _vm.refImageUrl,
                alt: _vm.refImageAlt,
                height: _vm.imgHeight,
                "max-height": _vm.imgHeight,
                onerror: "this.src='/no-icon.png'",
                contain: ""
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex-grow-1 align-self-start py-1" },
          [
            _vm.product.metaData.articletype.product_date
              ? _c("v-card-subtitle", { staticClass: "w-100 py-1" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm
                          .$dayjs(_vm.product.metaData.articletype.product_date)
                          .format("DD/MM/YYYY")
                      ) +
                      " "
                  )
                ])
              : _vm._e(),
            _c("v-card-title", { staticClass: "w-100 py-0" }, [
              _vm._v(_vm._s(_vm.product.title))
            ]),
            _c("v-card-subtitle", { staticClass: "w-100 mt-1 pt-0" }, [
              _vm._v(
                " " + _vm._s(_vm.product.metaData.articletype?.short_desc) + " "
              )
            ])
          ],
          1
        ),
        _c(
          "div",
          [
            _c("v-icon", { staticClass: "default--text mr-3 ml-1" }, [
              _vm._v("$chevronRight")
            ])
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }